import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const BoxStyled = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center ;
  ${(props) => (props.height === 'auto' ? 'height: auto;' : 'height: 200px;')};

`
