export const colors = {
  white: '#fff',
  black: '#000',
  waterLeaf: '#95E3DD',
  gallery: '#eee',
  errorBorder: '#fc5987',
  red: '#fc5987',
  blueSapphire: '#13576b',
  darkCyan: '#0a8a84',
  mountainMeadow: '#00bd9d',
  keppel: '#23c4ab',
  mediumTurquoise: '#69d1c5',
  middleBlueGreen: '#8bd7d2',
  powderBlue: '#b8e6e3',
}
