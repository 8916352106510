import { alertReducer } from '@redux/reducers/alert'
import { loginReducer } from '@redux/reducers/login'
import { userSearchReducer } from '@redux/reducers/user-search'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  loginReducer,
  userSearchReducer,
  alertReducer,
})

export default rootReducer
