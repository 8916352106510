import {
  SET_ALERT_MESSAGE,
  SET_ALERT_STATE,
  SET_ALERT_TYPE,
} from '@redux/constants'

const initialState = {
  type: 'info',
  message: 'Default alert message',
  active: false,
}

export const alertReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ALERT_MESSAGE:
      return {
        ...state,
        message: payload,
      }

    case SET_ALERT_TYPE:
      return {
        ...state,
        type: payload,
      }

    case SET_ALERT_STATE:
      return {
        ...state,
        active: payload,
      }

    default:
      return state
  }
}
