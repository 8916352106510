/**
 * Please add description, and a new line for every constant you declare
 */

// REDUX ACTION TYPES
// The following `increment` and `decrement` are simply examples
export const SET_INCREMENT = 'SET_INCREMENT'
export const SET_DECREMENT = 'SET_DECREMENT'

// User Login Related
export const SET_LOGOUT = 'SET_LOGOUT'
export const SET_LOGIN = 'SET_LOGIN'

// Logged-in User Detail Related
export const SET_USERNAME = 'SET_USERNAME'
export const SET_PASSWORD = 'SET_PASSWORD'
export const SET_USERNAME_ERROR = 'SET_USERNAME_ERROR'
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR'

export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
export const SET_ID = 'SET_ID'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'

// Auth token related
export const SET_ID_TOKEN = 'SET_ID_TOKEN'

export const clientId = '5orj7e7dr73q35dskl8nl9pohr'

// User Search Related
export const SET_USER_SEARCH = 'SET_USER_SEARCH'
export const SET_USER_RESULT = 'SET_USER_RESULT'

export const SET_ALERT_TYPE = 'SET_ALERT_TYPE'
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE'
export const SET_ALERT_STATE = 'SET_ALERT_STATE'
