import {
  SET_PASSWORD,
  SET_USERNAME,
  SET_ID_TOKEN,
  SET_PASSWORD_ERROR,
  SET_USERNAME_ERROR,
  SET_PHONE_NUMBER,
  SET_ID,
  SET_USER_DETAILS,
} from '@redux/constants'

const initialState = {
  id: '',
  username: process.env.NEXT_PUBLIC_TEST_LOGIN_USER || '',
  password: process.env.NEXT_PUBLIC_TEST_LOGIN_PASSWORD || '',
  usernameError: false,
  passwordError: false,
  phoneNumber: '',
  idToken: '',
  userDetails: {},
}

export const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USERNAME:
      return {
        ...state,
        username: payload,
      }

    case SET_PASSWORD:
      return {
        ...state,
        password: payload,
      }

    case SET_ID_TOKEN:
      return {
        ...state,
        idToken: payload,
      }

    case SET_USERNAME_ERROR:
      return {
        ...state,
        usernameError: payload,
      }

    case SET_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: payload,
      }

    case SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: payload,
      }

    case SET_ID:
      return {
        ...state,
        id: payload,
      }

    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: payload,
      }

    default:
      return state
  }
}
