import { SET_USER_SEARCH, SET_USER_RESULT } from '@redux/constants'

const initialState = {
  userSearchEmail: process.env.NEXT_PUBLIC_TEST_USER_SEARCH || '',
  didUpdateInitialCredentials: false,
  email: process.env.NEXT_PUBLIC_TEST_USER_SEARCH || '',
  id: '',
  phoneNumber: '',
  tempAuthCode: '',
  appleTransactions: [],
  deviceTokens: [],
  fulfilledPromotions: [],
  gender: '',
  genderPref: '',
  isBanned: false,
  isOptedIntoMarketingEmails: false,
  language: '',
  minutes: 0,
  totalFreeMinutes: 0,
  totalMinutesBought: 0,
  totalSpent: 0,
  transactions: [],
  type: '',
}

export const userSearchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_SEARCH:
      return {
        ...state,
        userSearchEmail: payload,
      }

    case SET_USER_RESULT:
      return {
        ...state,
        didUpdateInitialCredentials: payload.didUpdateInitialCredentials,
        email: payload.email,
        id: payload.id,
        phoneNumber: payload.phoneNumber,
        tempAuthCode: payload.tempAuthCode,
        appleTransactions: payload.appleTransactions,
        deviceTokens: payload.deviceTokens,
        fulfilledPromotions: payload.fulfilledPromotions,
        gender: payload.gender,
        genderPref: payload.genderPref,
        isBanned: payload.isBanned,
        isOptedIntoMarketingEmails: payload.isOptedIntoMarketingEmails,
        language: payload.language,
        minutes: payload.minutes,
        totalFreeMinutes: payload.totalFreeMinutes,
        totalMinutesBought: payload.totalMinutesBought,
        totalSpent: payload.totalSpent,
        transactions: payload.transactions,
        type: payload.type,
      }

    default:
      return state
  }
}
