import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

import { BoxStyled } from './styles'

export const Loader = (props) => {
  return (
    <BoxStyled {...props}>
      <CircularProgress {...props} />
    </BoxStyled>
  )
}
