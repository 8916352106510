import { createTheme } from '@mui/material'

import { colors } from './design-tokens'

export const theme = createTheme({
  mainMenu: {
    backgroundColor: '#160A4C',
    linkColor: '#fff',
    linkActiveColor: '#F9D34B',
    selected: '#21205C',
    childBorderColor: '#504974',
    childBorderActiveColor: '#F9D34B',
    chevronColor: '#6049CD',
  },
  pageHeader: {
    color: '#160A4C',
  },
  topNavigation: {
    backgroundColor: '#fff',
    color: '#160A4C',
  },
  notification: {
    bellColor: '#160A4C',
    buttonBackgroundColor: 'transparent',
    buttonBackgroundHoverColor: 'transparent',
    countBackgroundColor: '#84E9F5',
  },
  apsHeader: {
    fontFamily: 'Poppins',
  },
  content: {
    backgroundColor: '#EEEDF3',
  },

  // MUI overrides
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: colors.blueSapphire,
          padding: '12px 40px',
          '&:hover': {
            backgroundColor: colors.keppel,
          },
          '&:disabled': {
            backgroundColor: colors.gallery,
            color: colors.middleBlueGreen,
            opacity: '.4',
          },
          '&.MuiButton-outlinedError': {
            backgroundColor: colors.gallery,
            color: colors.middleBlueGreen,
            opacity: '.4',
          },
          '&.MuiButton-textError': {
            backgroundColor: colors.errorBorder,
            color: colors.white,
            border: '1px solid transparent',
          },
          '&.MuiButton-outlinedError:hover': {
            backgroundColor: colors.middleBlueGreen,
            border: `1px solid ${colors.errorBorder}`,
            color: colors.gallery,
            opacity: '.4',
          },
          '&.MuiButton-textError:hover': {
            backgroundColor: colors.white,
            border: `1px solid ${colors.errorBorder}`,
            color: colors.errorBorder,
          },
          '&.positive': {
            backgroundColor: colors.powderBlue,
            border: `1px solid ${colors.blueSapphire}`,
            color: colors.blueSapphire,
          },
          '&.positive:hover': {
            backgroundColor: colors.blueSapphire,
            border: `1px solid ${colors.powderBlue}`,
            color: colors.powderBlue,
          },
        },
      },
      variants: [
        {
          props: { variant: 'warning' },
          style: {
            backgroundColor: colors.keppel,
            color: colors.white,
          },
        },
        {
          props: { variant: 'success' },
          style: {
            backgroundColor: colors.keppel,
            color: colors.white,
          },
        },
        {
          props: { variant: 'danger' },
          style: {
            backgroundColor: colors.red,
            color: colors.white,
          },
        },
      ],
    },
  },
  menuItem: {
    color: '#fff',
    backgroundColor: '#6049CD',
    hoverBackgroundColor: '#F8F7FC',
    hoverColor: '#160A4C',
  },
  color: {
    palette: {
      purple: {
        main: '#6049CD',
        dark: '#160A4C',
        mid: '#F8F7FC',
        light: '#E7E5F2',
        lighter: '#F8F7FC',
      },
      purpleGrey: {
        main: '#504974',
        mid: '#959AAD',
        light: '#EEEDF3',
      },
      cyan: {
        main: '#69D9E0',
        light: '#84E9F5',
      },
      yellow: {
        main: '#F9D34B',
      },
      blue: {
        main: '#0E328E',
        dark: '#21205C',
      },
      white: {
        main: '#fff',
      },
    },
  },
  typography: {
    fontFamily: 'IBM Plex Sans',
    h1: {
      fontSize: '2rem',
      fontWeight: '600',
      lineHeight: '2.6rem',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: '500',
      lineHeight: '1.95rem',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '300',
      lineHeight: '1.95rem',
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: '500',
      lineHeight: '1.463rem',
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: '400',
      lineHeight: '1.463rem',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '1.3rem',
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.138rem',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: '300',
      lineHeight: '1.138rem',
    },
    button: {
      fontSize: '1rem',
      fontWeight: '500',
      lineHeight: '1.3rem',
    },
    subtitle1: {},
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: '500',
      lineHeight: '0.975rem',
    },
  },
  border: {
    radius: '10px',
    button: {
      radius: '4px',
    },
  },
  impactPrograms: {
    application: {
      header: {
        color: '#160A4C',
      },
    },
    impactCard: {
      backgroundColor: 'transparent',
    },
    myTraining: {
      statusGroup: {
        odd: {
          backgroundColor: '#F8F7FC',
        },
        even: {},
      },
      statusHeader: {
        color: '#160A4C',
      },
    },
  },
  capacityPrograms: {
    myTraining: {
      details: {
        header: {
          color: '#160A4C',
        },
      },
    },
    statusHeader: {
      color: '#160A4C',
    },
    sessionRow: {
      bottomBorderColor: '#959AAD',
      hoverBackgroundColor: '#F8F7FC',
    },
  },
})
