import styled from '@emotion/styled'
import { Alert } from '@mui/material'

export const AlertContainer = styled(Alert)`
  && {
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 700;
    flex: 1;
  }
`
